import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import TeamMemberCard from '../utils/TeamMemberCard'
import StudentCard from '../utils/StudentCard'
import teamphd from '../data/teams.json'
import masters from '../data/masters.json'
import coadvise from '../data/coadvise.json'
import alumni from '../data/alumni.json'
import under from '../data/underGrads.json'

import Header from '../utils/Header'

const TeamsScreen = () => {
  return (
    <div>
      <Header home={false} />
      <Container>
        <h1 className='my-4'>Our Team </h1>
        <hr />
        <p>
          In our dynamic research lab, our enthusiastic team is at the
          forefront of groundbreaking work in privacy-preserving and sustainable machine learning,
          cybersecurity, interdependent networks, and Generative AI. Fueled by a
          passion for innovation, our researchers collaborate seamlessly to
          explore the vast potential of these cutting-edge technologies. In the
          realm of distributed machine learning, we harness the power of
          collaborative computational resources to tackle complex problems
          efficiently. Simultaneously, our commitment to sustainability is
          reflected in our pursuit of eco-friendly machine learning practices,
          aiming to minimize environmental impact.
        </p>
        <hr className='blank-space' />

        <h3 className='my-3'>Lab Director </h3>
        <TeamMemberCard />
        <hr className='blank-space' />
        <Row>
          {teamphd.map((record) => (
            <Col xs={12} lg={4} md={6}>
              {record.id === 1 && <h3 className='my-3'>Ph.D. Students</h3>}
              {record.id > 1 && <div style={{ marginTop: '4.2rem' }}></div>}

              <StudentCard
                id={record.id}
                name={record.name}
                researchGate={record.researchGate}
                position={record.position}
                interests={record.interests}
                github={record.github}
                img={record.img}
                website={record.website}
                scholar={record.scholar}
                LinkedIn={record.LinkedIn}
                achievements={record.achievements}
              />
            </Col>
          ))}
        </Row>

        <hr className='blank-space' />
        <h3 className='my-3'>Masters Students </h3>
        <Row>
          {masters.map((record) => (
            <Col xs={12} lg={4} md={6}>
              <StudentCard
                id={record.id}
                name={record.name}
                researchGate={record.researchGate}
                position={record.position}
                interests={record.interests}
                github={record.github}
                img={record.img}
                website={record.website}
                scholar={record.scholar}
                LinkedIn={record.LinkedIn}
                achievements={record.achievements}
              />
            </Col>
          ))}
        </Row>
        <hr className='blank-space' />
        <h3 className='my-3'>Undergraduate Students </h3>

        <Row>
          {under.map((record) => (
            <Col xs={12} lg={4} md={6}>
              <StudentCard
                id={record.id}
                name={record.name}
                researchGate={record.researchGate}
                position={record.position}
                interests={record.interests}
                github={record.github}
                img={record.img}
                website={record.website}
                scholar={record.scholar}
                LinkedIn={record.LinkedIn}
                achievements={record.achievements}
              />
            </Col>
          ))}
        </Row>

        <hr className='blank-space' />
        <h3 className='my-3'>SPEED Lab Alumni</h3>

        <Row>
          {alumni.map((record) => (
            <Col xs={12} lg={4} md={6}>
              <StudentCard
                id={record.id}
                name={record.name}
                researchGate={record.researchGate}
                position={record.position}
                interests={record.interests}
                github={record.github}
                img={record.img}
                website={record.website}
                scholar={record.scholar}
                LinkedIn={record.LinkedIn}
                achievements={record.achievements}
              />
            </Col>
          ))}
        </Row>
        {/* <div class='scard dark'>
          <img
            src='https://codingyaar.com/wp-content/uploads/chair-image.jpg'
            class='card-img-top'
            alt='...'
          />
          <div class='scard-body'>
            <div class='stext-section'>
              <h5 class='scard-title'>Card title</h5>
              <p class='scard-text'>
                Some quick example text to build on the card's content.
              </p>
            </div>
            <div class='scta-section'>
              <div>$129.00</div>
              <a href='#' class='btn btn-light'>
                Buy Now
              </a>
            </div>
          </div>
        </div> */}
        {/* <div class='container-fluid'>
          <div class='row'>
            <div class='col-12 mt-3'>
              <div class='card'>
                <div class='card-horizontal'>
                  <div class='img-square-wrapper'>
                    <img
                      class=''
                      src='http://via.placeholder.com/300x180'
                      alt='Card image cap'
                    />
                  </div>
                  <div class='card-body'>
                    <h4 class='card-title'>Card title</h4>
                    <p class='card-text'>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Container>
    </div>
  )
}

export default TeamsScreen
